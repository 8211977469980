import { Plus } from "@phosphor-icons/react";
import { Select } from "antd";
import clsx from "clsx";
import { useApp } from "context/AppContext";
import { useQuestionnaire } from "pages/ShareQuestionnaire/Context";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "x-wings";
import EmptyState from "./Components/EmptyState";
import AddCoTravelerModal from "./Components/Modal";
import "./styles.css";

const AddCoTraveller: React.FC<{ className?: string }> = ({ className }) => {
	const navigate = useNavigate();
	const { order, applicationId, coTravelers } = useApp();
	const { completionPercentage, isLoading } = useQuestionnaire();
	const [isOpen, setIsOpen] = useState(false);
	const [isFirstRun, setIsFirstRun] = useState(true);
	const travellersContainerRef = useRef<HTMLDivElement>(null);

	const traveler = Object.values(coTravelers).find((t) => t.application_id === applicationId);
	const name = [traveler?.first_name, traveler?.last_name].filter(Boolean).join(" ");
	const percentage = completionPercentage >= 100 ? "100" : completionPercentage.toFixed(1);

	const setSelectionPill = (activeTravelerEle: HTMLElement) => {
		if (!travellersContainerRef.current) return;
		const selectionPillElel = travellersContainerRef.current.querySelector(".selection-pill") as HTMLSpanElement;
		if (activeTravelerEle && selectionPillElel) {
			const travellersContainerRect = travellersContainerRef.current.getBoundingClientRect();
			const activeTravellerRect = activeTravelerEle.getBoundingClientRect();
			selectionPillElel.style.setProperty(
				"--left",
				`${activeTravellerRect.left - travellersContainerRect.left}px`
			);
			selectionPillElel.style.setProperty("--top", `${activeTravellerRect.top - travellersContainerRect.top}px`);
			selectionPillElel.style.setProperty("--height", `${activeTravellerRect.height}px`);
			selectionPillElel.style.setProperty("--width", `${activeTravellerRect.width}px`);
		}
	};

	useEffect(() => {
		setIsFirstRun(true);
	}, [applicationId]);

	useEffect(() => {
		if (!isFirstRun) return;
		let requestId: number | null = null;
		function playAnimation() {
			const ref = travellersContainerRef.current;
			const activeTravellerEle = ref?.querySelector('.traveller[data-active="true"]') as HTMLDivElement;
			const selectionPillElel = ref?.querySelector(".selection-pill") as HTMLSpanElement;
			if (!activeTravellerEle || !selectionPillElel) {
				requestId = requestAnimationFrame(playAnimation);
				return;
			}
			setSelectionPill(activeTravellerEle);
			setIsFirstRun(false);
		}
		requestId = requestAnimationFrame(playAnimation);
		return () => {
			requestId !== null && cancelAnimationFrame(requestId);
		};
	});

	const handleModalClose = () => {
		setIsOpen(false);
	};

	const handleModalOpen = () => {
		setIsOpen(true);
	};

	const handleTravellerClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
		if (!order) return;
		const target = e.currentTarget as HTMLElement;
		const activeTravellerId = target.dataset.applicationId;
		if (activeTravellerId) {
			setSelectionPill(target);
			// setApplicationId(activeTravellerId);
			navigate(`/questionnaire/${order?._id}?application_id=${activeTravellerId}`);
		}
	};

	const handleTravellerChange = (value: string) => {
		navigate(`/questionnaire/${order?._id}?application_id=${value}`);
	};

	return (
		<div className={className}>
			{Object.keys(coTravelers).length < 2 ? (
				<div
					className={
						"relative md:shadow flex md:flex-col items-stretch justify-between bg-[#F5F5F5] md:bg-white overflow-hidden rounded-xl p-3 " +
						"before:content-none md:before:content-[''] before:absolute before:inset-[-1px] before:bg-conic-co-traveler before:z-[-1] before:rounded-[calc(0.75rem+1px)]"
					}>
					<EmptyState />
					<Button
						className="!hidden md:!flex items-center justify-center gap-2 m-4"
						style={{ paddingInline: "0.75rem" }}
						onClick={handleModalOpen}
						icon={<Plus size={16} weight="bold" />}>
						Add co-traveler
					</Button>
					<Button
						className="md:!hidden"
						type="primary"
						style={{ paddingInline: "0.75rem" }}
						icon={<Plus size={16} weight="bold" />}
						onClick={handleModalOpen}>
						Add
					</Button>
				</div>
			) : (
				<div className="">
					<div
						ref={travellersContainerRef}
						className="hidden md:flex md:flex-col flex-wrap md:gap-0 gap-3 items-stretch relative isolate md:shadow-[inset_2px_0px_0px_#EEF2FF]">
						<div className="absolute selection-pill transition-all -z-10 md:bg-primary-600 rounded-md md:w-0.5 w-[var(--width)] h-[var(--height)] top-[var(--top)] left-[var(--left)]"></div>
						{Object.entries(coTravelers).map(([id, traveler]) => {
							return (
								<div
									key={id}
									className={clsx(
										"flex items-stretch gap-3 px-3 py-1.5 font-medium text-[13px] text-[#4B5565] cursor-pointer tracking-[-0.04px] hover:bg-gray-50/30 rounded-full z-10 traveller transition-all border border-gray-700 border-dashed md:!border-none md:!bg-transparent",
										applicationId === traveler.application_id &&
											"!text-primary-700 cursor-default !border-primary-600 !border-solid bg-primary-50"
									)}
									data-application-id={traveler.application_id}
									data-active={applicationId === traveler.application_id}
									onClick={handleTravellerClick}>
									<span className="flex-1 text-ellipsis overflow-hidden whitespace-nowrap">
										{[traveler?.first_name, traveler?.last_name].filter(Boolean).join(" ")}
									</span>
									{applicationId === traveler.application_id && !isLoading && (
										<span
											className={clsx(
												"pl-2.5 pr-2 rounded-full bg-amber-100 text-[9px] leading-5 text-amber-700 font-medium",
												completionPercentage > 50 && "bg-emerald-100 text-emerald-700"
											)}>
											{percentage}%
										</span>
									)}
								</div>
							);
						})}
					</div>
					<Button
						className="!hidden md:!flex items-center justify-center gap-2 my-4 me-4"
						style={{ paddingInline: "0.75rem" }}
						onClick={handleModalOpen}
						icon={<Plus size={16} weight="bold" />}>
						Add co-traveler
					</Button>
					<div className="md:hidden flex flex-col gap-5">
						<div className="flex items-center gap-2">
							<Select className="flex-1" value={applicationId} onChange={handleTravellerChange}>
								{Object.entries(coTravelers).map(([id, traveler]) => {
									return (
										<Select.Option key={id} value={traveler.application_id}>
											{[traveler?.first_name, traveler?.last_name].filter(Boolean).join(" ")}
										</Select.Option>
									);
								})}
							</Select>
							<Button
								className="md:!hidden"
								type="primary"
								style={{ paddingInline: "0.75rem" }}
								icon={<Plus size={16} weight="bold" />}
								onClick={handleModalOpen}>
								Add
							</Button>
						</div>
						{traveler && (
							<p className="text-base tracking-[-0.12px] font-semibold text-gray-900 flex items-center gap-2">
								{name}
								{applicationId === traveler?.application_id && !isLoading && (
									<span
										className={clsx(
											"pl-2.5 pr-2 rounded-full bg-amber-100 text-[9px] leading-5 text-amber-700 font-medium",
											completionPercentage > 50 && "bg-emerald-100 text-emerald-700"
										)}>
										{percentage}%
									</span>
								)}
							</p>
						)}
					</div>
				</div>
			)}
			<AddCoTravelerModal isOpen={isOpen} onClose={handleModalClose} />
		</div>
	);
};

export default AddCoTraveller;

