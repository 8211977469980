import { useState } from "react";

type TUseConditionalQuestionnaire = {
	questionnaire?: GenerateQuestionnaire;
	setQuestionnaire: React.Dispatch<React.SetStateAction<GenerateQuestionnaire | undefined>>;
	getAnswerByVisaQuestionId: (visaQuestionId: string) => AnswerData | undefined;
};

type TRenders = GenerateQuestionnaire["questionMap"][string]["renders"][number];
type TQuestionMap = GenerateQuestionnaire["questionMap"][string];

const useConditionalQuestionnaire = ({
	questionnaire,
	setQuestionnaire,
	getAnswerByVisaQuestionId
}: TUseConditionalQuestionnaire) => {
	const [renderedQuestions, setRenderedQuestions] = useState<Record<string, string[]>>({});

	const getQuestion = (id: string) => {
		if (questionnaire === undefined) return undefined;
		return questionnaire.questionMap[id];
	};

	const getUpdatedQuestionnaireBasedOnRenders = (
		_prev: GenerateQuestionnaire,
		renders: TRenders[],
		questionId: string,
		value: string
	) => {
		if (_prev === undefined) return _prev;
		const prev = { ..._prev };

		renderedQuestions[questionId]?.forEach((id) => {
			const q: TQuestionMap | undefined = prev.questionMap[id];
			if (q) {
				q.is_root = false;
			} else {
				console.warn(`QuestionId: ${id} was not found in QuestionnaireState`, renderedQuestions);
			}
		});

		setRenderedQuestions((prev) => ({
			...prev,
			[questionId]: []
		}));

		const renderQuestions = (questionsIds: string[]) => {
			setRenderedQuestions((prev) => ({
				...prev,
				[questionId]: questionsIds
			}));
			questionsIds.forEach((id) => {
				const q: TQuestionMap | undefined = prev!.questionMap![id];
				if (q) {
					q.is_root = true;
					const answer = getAnswerByVisaQuestionId(id);
					if (answer) {
						renderQuestionsOnAnswerChange(id, answer.answer);
					}
				} else {
					console.warn(`QuestionId: ${id} was not found in QuestionnaireState`);
				}
			});
		};

		renders.forEach((render) => {
			switch (render.operation) {
				case "answers":
					if (value) {
						renderQuestions(render.question_ids);
					}
					break;
				case "eq":
					if (value === render.value) {
						renderQuestions(render.question_ids);
					}
					break;
				case "neq":
					if (value !== render.value) {
						renderQuestions(render.question_ids);
					}
					break;
				case "gt":
					if (parseInt(value) > parseInt(render.value)) {
						renderQuestions(render.question_ids);
					}
					break;
				case "gte":
					if (parseInt(value) >= parseInt(render.value)) {
						renderQuestions(render.question_ids);
					}
					break;
				case "lt":
					if (parseInt(value) < parseInt(render.value)) {
						renderQuestions(render.question_ids);
					}
					break;
				case "lte":
					if (parseInt(value) <= parseInt(render.value)) {
						renderQuestions(render.question_ids);
					}
					break;
				case "includes":
					if (String(value).includes(render.value)) {
						renderQuestions(render.question_ids);
					}
					break;
				case "nincludes":
					if (!String(value).includes(render.value)) {
						renderQuestions(render.question_ids);
					}
					break;
				default:
					break;
			}
		});

		Object.entries(prev.questionMap).forEach(([questionId, question]) => {
			if (question?.is_root === false) {
				question.renders?.forEach(({ question_ids }) => {
					question_ids.forEach((id) => {
						if (prev.questionMap[id] !== undefined) prev.questionMap[id].is_root = false;
					});
				});
			}
		});

		return prev;
	};

	const renderQuestionsOnAnswerChange = (questionId: string, val: string[]) => {
		const que = getQuestion(questionId);
		if (que === undefined) return;
		const ques = { ...questionnaire };
		if (ques === undefined) return;
		const value = val.join(",");

		setQuestionnaire((prev) => getUpdatedQuestionnaireBasedOnRenders(prev!, que.renders, questionId, value));
	};

	return {
		renderQuestionsOnAnswerChange
	};
};

export default useConditionalQuestionnaire;
