import { useApp } from "context/AppContext";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { generateQuestionnaire, getAnswerStatus } from "../Api";
import useConditionalQuestionnaire from "../useConditionalQuestionnaire";

const QuestionnaireContext = createContext<QuestionnaireContextValues | undefined>(undefined);

const QuestionnaireProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
	const { getOrder, getCoTravelers, applicationId } = useApp();
	const [isLoading, setIsLoading] = useState(false);
	const [questionnaire, setQuestionnaire] = useState<GenerateQuestionnaire>();
	const [questions, setQuestions] = useState<Question[]>([]);
	const [answers, setAnswers] = useState<AnswerData[]>([]);
	const [categoryStatus, setCategoryStatus] = useState<Category>({});
	const [isFormCompleted, setIsFormCompleted] = useState<[boolean, boolean]>([false, false]); // [questionnaire, document]
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [isCoTravelerModalOpen, setIsCoTravelerModalOpen] = useState(false);
	const navigate = useNavigate();
	const { orderId } = useParams();

	const getAnswerByVisaQuestionId = (visaQuestionId: string) =>
		answers.find((_) => _.visa_questions_id === visaQuestionId);

	const updateAnswerByVisaQuestionId = (visaQuestionId: string, answer: AnswerData) => {
		setAnswers((prev) => {
			const index = prev.findIndex((_) => _.visa_questions_id === visaQuestionId);
			if (index === -1) return [...prev, answer];
			const _ = [...prev];
			_[index] = answer;
			return _;
		});
	};

	const { renderQuestionsOnAnswerChange } = useConditionalQuestionnaire({
		questionnaire,
		setQuestionnaire,
		getAnswerByVisaQuestionId
	});

	useEffect(() => {
		answers.forEach((ans) => {
			renderQuestionsOnAnswerChange(ans.visa_questions_id, ans.answer);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [answers]);

	useEffect(() => {
		if (!orderId) {
			navigate(`/notfound`);
			return;
		}
		getOrder(orderId);
		getCoTravelers(orderId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate, orderId]);

	useEffect(() => {
		if (!orderId) {
			navigate(`/notfound`);
			return;
		}

		if (!applicationId) return;

		const fetch = async () => {
			try {
				setIsLoading(true);
				setQuestions([]);
				setHasUnansweredQuestions(false);
				const promises = [generateQuestionnaire(orderId), getAnswerStatus(applicationId)] as const;

				const [questionnaireRes, answerStatusRes] = await Promise.all(promises);
				setQuestionnaire({
					questionsWithRank: questionnaireRes.data.data.questionsWithRank,
					questionMap: questionnaireRes.data.data.questionMap
				});
				setAnswers(answerStatusRes.data.data);
			} catch (error) {
			} finally {
				setIsLoading(false);
			}
		};

		fetch();
	}, [navigate, orderId, getOrder, applicationId]);

	useEffect(() => {
		if (isLoading) return;
		if (questionnaire === undefined) return;

		const questions = questionnaire.questionsWithRank.reduce((prev, { id, rank }) => {
			const ques = questionnaire.questionMap[id];
			if (ques && ques.is_root && ques.answer_source === "USER_INPUT" && ques.type !== "DOCUMENT")
				prev.push({
					...ques,
					id,
					rank,
					answer_data: answers.find((_) => _.visa_questions_id === id)
				});
			return prev;
		}, [] as Question[]);

		setQuestions(questions);
		setHasUnansweredQuestions((prev) => {
			if (prev) return prev;
			const _ = questions.some(
				(que) =>
					!que.answer_data ||
					que.answer_data?.status === "UNSTARTED" ||
					que.answer_data?.status === "REJECTED"
			);
			return _;
		});

		setCategoryStatus(
			Object.entries(questionnaire.questionMap).reduce((prev, [id, curr]) => {
				if (prev[curr.category]) {
					prev[curr.category].totalCount++;
				} else {
					prev = {
						...prev,
						[curr.category]: {
							active: false,
							rejectedCount: 0,
							completedCount: 0,
							totalCount: 1
						}
					};
				}

				if (answers.find((_) => _.visa_questions_id === id)?.status === "ANSWERED") {
					prev[curr.category].completedCount++;
				} else if (answers.find((_) => _.visa_questions_id === id)?.status === "REJECTED") {
					prev[curr.category].rejectedCount++;
				}

				return prev;
			}, {} as Category)
		);
	}, [answers, isLoading, questionnaire]);

	// const userInputQuestionsCount = useMemo(() => {
	// 	return Object.values(questionnaire?.questionMap ?? {}).filter(
	// 		(que) => que.answer_source === "USER_INPUT" && que.type !== "DOCUMENT"
	// 	).length;
	// }, [questionnaire]);

	const completionPercentage = useMemo(() => {
		const total = questions.length;
		const completed = questions.filter(
			(que) =>
				que.answer_data?.status === "ANSWERED" ||
				que.answer_data?.status === "APPROVED" ||
				que.answer_data?.status === "NA"
		).length;
		return !total ? 0 : (completed / total) * 100;
	}, [questions]);

	const [hasUnansweredQuestions, setHasUnansweredQuestions] = useState(false);

	useEffect(() => {
		if (questions.length === 0) return;
	}, [questions]);

	return (
		<QuestionnaireContext.Provider
			value={{
				questions,
				category: categoryStatus,
				answers,
				completionPercentage,
				isLoading,
				isSidebarOpen,
				isCoTravelerModalOpen,
				isFormCompleted,
				hasUnansweredQuestions,
				setHasUnansweredQuestions,
				setIsFormCompleted,
				setIsCoTravelerModalOpen,
				setIsSidebarOpen,
				setAnswers,
				setQuestions,
				setCategory: setCategoryStatus,
				updateAnswerByVisaQuestionId
			}}>
			{children}
		</QuestionnaireContext.Provider>
	);
};
export const useQuestionnaire = () => {
	const context = useContext(QuestionnaireContext);

	if (!context) {
		throw new Error("Cannot use useQuestionnaire outside the Provider");
	}

	return context;
};

export default QuestionnaireProvider;

