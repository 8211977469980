import { Spin } from "antd";
import { useApp } from "context/AppContext";
import type { FormRef } from "rc-field-form/lib/interface";
import { useEffect, useRef, useState } from "react";
import AddCoTraveller from "./Components/AddCoTraveller";
import CountBox from "./Components/CountBox";
import QuestionnaireForm from "./Components/Form";
import QuestionnaireCompleted from "./Components/Form/Components/QuestionnaireCompleted";
import Header from "./Components/Header";
import { useQuestionnaire } from "./Context";

const Questionnaire: React.FC = () => {
	const { applicationId } = useApp();
	const { isLoading, hasUnansweredQuestions } = useQuestionnaire();
	const [firstRun, setFirstRun] = useState(true);
	const countBoxRef = useRef<HTMLDivElement>(null);
	const questionnaireRef = useRef<FormRef>(null);

	useEffect(() => {
		setFirstRun(true);
	}, [applicationId]);

	useEffect(() => {
		const form = questionnaireRef.current?.nativeElement as HTMLFormElement;
		const countbox = countBoxRef.current?.querySelector(".question-count-container") as HTMLElement;
		if (!form || !countbox) return;

		const setTopAndHeightOfActivePill = (form: HTMLFormElement, countbox: HTMLElement) => {
			if (!countbox || !form) return;

			const formItems = form.querySelectorAll<HTMLDivElement>("div[data-question-no]");

			const containerTop = form.scrollTop;
			const containerBottom = containerTop + form.clientHeight;
			const activeQuestionNumber = [-1, -1];

			formItems.forEach((item, index) => {
				const itemTop = item.offsetTop;
				const itemBottom = itemTop + item.offsetHeight;

				// Check if the item is within the visible area
				if (itemBottom > containerTop && itemTop < containerBottom) {
					// item.classList.add("visible");
					activeQuestionNumber[0] === -1 && (activeQuestionNumber[0] = index);
					if (index === formItems.length - 1) {
						activeQuestionNumber[0] !== -1 &&
							activeQuestionNumber[1] === -1 &&
							(activeQuestionNumber[1] = index);
					}
				} else {
					// item.classList.remove("visible");
					activeQuestionNumber[0] !== -1 &&
						activeQuestionNumber[1] === -1 &&
						(activeQuestionNumber[1] = index - 1);
				}
			});
			const activeCounts = countbox.querySelectorAll(
				`:is(.question-count[data-que-no='${activeQuestionNumber[0]}'],.question-count[data-que-no='${activeQuestionNumber[1]}'])`
			);

			if (!activeCounts.item(0) || !activeCounts.item(1)) return;
			if (firstRun) {
				setFirstRun(false);
			}
			const countBoxRect = countbox.getBoundingClientRect();
			const firstCountRect = activeCounts.item(0).getBoundingClientRect();
			const lastCountRect = activeCounts.item(1).getBoundingClientRect();

			countbox.style.setProperty("--activeHeight", `${lastCountRect.bottom - firstCountRect.top}px`);
			countbox.style.setProperty("--activeTop", `${firstCountRect.top - countBoxRect.top}px`);
		};

		const listener = (event: Event) => {
			countbox.querySelector(".question-count-active-pill")?.scrollIntoView({
				behavior: "auto",
				block: "center"
			});
			setTopAndHeightOfActivePill(event.target as HTMLFormElement, countbox);
		};

		if (firstRun) {
			setTopAndHeightOfActivePill(form, countbox);
		}

		form?.addEventListener("scroll", listener);
		return () => {
			form?.removeEventListener("scroll", listener);
		};
	});

	return (
		<main className="relative grid grid-rows-[auto_min-content_1fr] md:grid-rows-[auto_1fr] md:grid-cols-[min-content_minmax(600px,1fr)_min-content] place-content-stretch min-h-screen max-h-screen overflow-hidden md:[grid-template-areas:'header_header_header'_'countbox_questionnaire_co-traveler'] [grid-template-areas:'header'_'co-traveler'_'questionnaire']">
			{/* <Sidebar /> */}
			<Header />
			{!isLoading && (
				<>
					{!hasUnansweredQuestions && (
						<QuestionnaireCompleted className="self-start m-8 [grid-area:countbox/questionnaire/co-traveler]" />
					)}
					{hasUnansweredQuestions && (
						<>
							<CountBox ref={countBoxRef} className="[grid-area:countbox]" />
							<QuestionnaireForm
								ref={questionnaireRef}
								className="[grid-area:questionnaire] max-w-[600px]"
							/>
						</>
					)}
				</>
			)}
			<AddCoTraveller className="[grid-area:co-traveler] md:ms-auto md:m-8 m-5" />

			{isLoading && (
				<div className="fixed inset-0  bg-gray-900/18 grid place-items-center z-[999999]">
					<Spin size="large" />
				</div>
			)}
		</main>
	);
};

export default Questionnaire;

export { useQuestionnaire };

