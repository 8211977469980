import clsx from "clsx";
import { useQuestionnaire } from "pages/ShareQuestionnaire";
import { forwardRef } from "react";

const CountBox = forwardRef<HTMLDivElement, { className?: string }>(({ className }, ref) => {
	const { questions } = useQuestionnaire();

	return (
		<div ref={ref} className={clsx("relative scrollbar-hidden overflow-auto md:block hidden", className)}>
			<div className="question-count-container sticky top-0 z-10 flex flex-col place-self-start overflow-hidden  md:ps-6 md:pe-4 md:py-6 ps-4 pe-3 py-4 isolate">
				<span
					className={
						"sticky top-0 grid h-10 w-10 place-items-center rounded-lg text-base text-gray-500" +
						" border border-solid border-gray-300 bg-gray-50 font-medium shadow"
					}>
					Q
				</span>
				<span className="question-count-active-pill absolute top-[var(--activeTop)] h-[var(--activeHeight)] w-10 rounded-lg bg-primary-50 shadow-md ring-1 ring-gray-900/10 transition-all duration-150 ease-out -z-10"></span>
				{questions.map(({ id }, index) => (
					<span
						key={id}
						data-que-count-id={id}
						data-que-no={index}
						className={clsx(
							"question-count grid h-10 w-10 cursor-pointer place-items-center text-base font-medium text-gray-500 transition-all duration-500"
						)}
						onClick={() => {
							const element = document.querySelector(`[data-question-id="${id}"]`);
							if (element) {
								element.scrollIntoView({ behavior: "smooth", block: "center" });
							}
						}}>
						{index + 1}
					</span>
				))}
			</div>
		</div>
	);
});
export default CountBox;

