import logoWithName from "asset/logo-with-name.svg";
import { useApp } from "context/AppContext";

const Header: React.FC = () => {
	const { companyDetails } = useApp();
	return (
		<header className="bg-white row-span-1 flex justify-center items-center gap-3 z-10 border-b border-solid border-gray-200 py-3">
			<picture>
				{/* <source media="(max-width: 767px)" srcSet={logo} /> */}
				<source media="(min-width: 768px)" srcSet={companyDetails?.organisation.agency_logo ?? logoWithName} />
				<img
					className="flex justify-center mix-blend-multiply max-h-6"
					src={companyDetails?.organisation.agency_logo ?? logoWithName}
					height={28}
					alt={companyDetails?.organisation.name}
				/>
			</picture>
		</header>
	);
};

export default Header;

