import AppProvider from "context/AppContext";
import ShareQuestionnaire from "pages/ShareQuestionnaire";
import QuestionnaireProvider from "pages/ShareQuestionnaire/Context";
import SignUp from "pages/SignUp";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "x-wings";

function GlobalLoaderWrapper() {
	return (
		<AppProvider>
			<ThemeProvider>
				<QuestionnaireProvider>
					<Outlet />
				</QuestionnaireProvider>
			</ThemeProvider>
		</AppProvider>
	);
}

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/questionnaire/*" element={<GlobalLoaderWrapper />}>
					<Route path=":orderId/signup" element={<SignUp />} />
					<Route path=":orderId" element={<ShareQuestionnaire />} />
				</Route>
				<Route path="*" element={"Page not found! Contact travel agent"} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;

