import { Form } from "antd";
import { clsx } from "clsx";
import type { FormRef } from "rc-field-form/lib/interface";
import { forwardRef } from "react";
import { useQuestionnaire } from "../..";
import QuestionnaireFormItem from "./Components/QuestionnaireFormItem";
import QuestionnaireSubmit from "./Components/QuestionnaireSubmit";

const QuestionnaireForm = forwardRef<FormRef, { className?: string }>(({ className }, ref) => {
	const { questions } = useQuestionnaire();
	const [form] = Form.useForm();

	const handleSubmitButtonState = () => {
		form.setFieldValue(
			"disable-submit-continue-button",
			!form.isFieldsTouched(
				questions
					.filter(
						(que) =>
							que.is_required &&
							que.is_root &&
							que.answer_data?.status !== "ANSWERED" &&
							que.answer_data?.status !== "APPROVED"
					)
					.map((que) => que.id),
				true
			) ||
				form.getFieldsError().filter(({ errors }) => {
					return errors.length;
				}).length > 0
		);
	};

	return (
		<Form
			form={form}
			ref={ref}
			layout="vertical"
			initialValues={{ "disable-submit-continue-button": true }}
			onFieldsChange={handleSubmitButtonState}
			className={clsx(
				"flex flex-col items-stretch gap-10 scrollbar-hidden w-full overflow-auto md:ps-4 md:pe-6 md:py-6 p-5 pt-3",
				className
			)}>
			{questions.map(({ id, ...que }, index) => {
				if (!que.is_root) return null;
				return <QuestionnaireFormItem key={id} {...que} id={id} index={index} />;
			})}
			{questions.length > 0 && <QuestionnaireSubmit />}
		</Form>
	);
});

export default QuestionnaireForm;

